exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-thank-you-js": () => import("./../../../src/pages/contact-thank-you.js" /* webpackChunkName: "component---src-pages-contact-thank-you-js" */),
  "component---src-pages-contact-us-demo-js": () => import("./../../../src/pages/contact-us-demo.js" /* webpackChunkName: "component---src-pages-contact-us-demo-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-furniture-js": () => import("./../../../src/pages/furniture.js" /* webpackChunkName: "component---src-pages-furniture-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-subscribe-thank-you-js": () => import("./../../../src/pages/subscribe-thank-you.js" /* webpackChunkName: "component---src-pages-subscribe-thank-you-js" */),
  "component---src-templates-blog-category-page-js": () => import("./../../../src/templates/BlogCategoryPage.js" /* webpackChunkName: "component---src-templates-blog-category-page-js" */),
  "component---src-templates-blog-detail-page-js": () => import("./../../../src/templates/BlogDetailPage.js" /* webpackChunkName: "component---src-templates-blog-detail-page-js" */),
  "component---src-templates-product-detail-page-js": () => import("./../../../src/templates/ProductDetailPage.js" /* webpackChunkName: "component---src-templates-product-detail-page-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/Footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-navbar-js": () => import("./../../../src/components/Navbar.js" /* webpackChunkName: "slice---src-components-navbar-js" */)
}

